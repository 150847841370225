import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link as RoutingLink } from 'react-router-dom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import React from 'react';

export function BackArrowHeader() {
  return (
    <Box>
      <Typography variant="h5" component="h1" gutterBottom>
        <RoutingLink to="/">
          <ArrowUpwardIcon
            style={{
              transform: 'rotate(-90deg)',
              color: 'white',
            }}
          />
        </RoutingLink>
      </Typography>
    </Box>
  );
}
