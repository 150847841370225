import { HCOLocation } from '../../../data/hco/model/HCOLocations';
import HCOHomeParagraphDescription, {
  TextSize,
} from './HCOHomeParagraphDescription';
import appTheme from '../../../theme/appTheme';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function HCOHomeLocationInfo(location: HCOLocation) {
  const { t } = useTranslation();
  let title = location.titleKey ? t(location.titleKey).toString() : '';
  let description = location.descriptionKey
    ? t(location.descriptionKey).toString()
    : '';
  let phone = location.phone ? location.phone : '';

  return (
    <>
      <HCOHomeParagraphDescription
        text={title}
        textColor={'white'}
        textSize={TextSize.MEDIUM}
        noSpacing={true}
      />
      {description ? (
        <HCOHomeParagraphDescription
          text={description}
          textSize={TextSize.MEDIUM}
          noSpacing={true}
        />
      ) : (
        ''
      )}
      {phone ? (
        <a
          href="tel:+496170961709"
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: 'none',
          }}
        >
          <HCOHomeParagraphDescription
            text={phone}
            textSize={TextSize.MEDIUM}
            noSpacing={true}
          />
        </a>
      ) : (
        ''
      )}
      <a
        href={location.mapsUrl.toString()}
        target="_blank"
        rel="noreferrer"
        style={{
          textDecorationColor: appTheme.palette.primary.main,
        }}
      >
        <Typography color={appTheme.palette.primary.main}>
          {t('hco.location.gpsCoordinates')}
        </Typography>
      </a>
      <Divider sx={{ mt: 2, mb: 2 }} />
    </>
  );
}
