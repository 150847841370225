import React from 'react';
import {
  HCORegistration,
  sex,
  tshirtSizes,
} from '../../data/hco/model/HCORegistration';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

interface HCOStatisticsProps {
  registrations?: HCORegistration[];
}

export function HCOStatistics({ registrations }: HCOStatisticsProps) {
  const tshirtNumber = (thirtSize: string, sex: string) => {
    let count = 0;
    registrations?.forEach((user) => {
      if (user.sex === sex && user.tshirtSize === thirtSize) {
        count = count + 1;
      }
    });
    return count;
  };

  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell component="th">{}</TableCell>
          {sex.map((s) => (
            <TableCell component="th">{s.label}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {tshirtSizes.map((ts) => (
          <TableRow>
            <TableCell>
              <Typography id="tshirtSize">{ts.label}</Typography>
            </TableCell>
            <TableCell>
              <Typography id="tshirtSize">
                {tshirtNumber(ts.value, sex[0].value)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography id="tshirtSize">
                {tshirtNumber(ts.value, sex[1].value)}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
