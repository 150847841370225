import React, { useRef } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { HCORegistration } from '../../data/hco/model/HCORegistration';
import { Button } from '@mui/material';

interface HCOParticipantsExcelProps {
  registrations?: HCORegistration[];
}

export function HCOParticipantsExcelTable({
  registrations,
}: HCOParticipantsExcelProps) {
  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'HCO participants table',
    sheet: 'HCOParticipanti',
  });

  const keys = () => {
    return registrations ? Object.keys(registrations[0]) : [];
  };

  const userValue = (
    user: HCORegistration,
    userKey: String,
    indexUser: number
  ) => {
    if (userKey === 'id') return indexUser;

    type ObjectKey = keyof typeof user;
    const key = userKey as ObjectKey;
    let element = user[key];
    return element ? element.toString() : '';
  };

  return (
    <div>
      <Button onClick={onDownload}> Excel export </Button>
      <br />
      <br />

      <table ref={tableRef}>
        <tbody>
          <tr>
            {keys().map((userKey, index) => (
              <th>{userKey}</th>
            ))}
          </tr>
          {registrations?.map((user, indexUser) => (
            <tr>
              {keys().map((userKey) => (
                <td>{userValue(user, userKey, indexUser)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
