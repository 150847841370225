const baseLocation = {
  coordinates: { north: '44°52\'32.8"N', east: '22°25\'02.9"E' },
  mapsUrl: 'https://maps.app.goo.gl/PT3cj4eQup2tsHou6',
};
const cragLocation = {
  coordinates: { north: '44°52\'31.0"N', east: '22°25\'19.7"E' },
  mapsUrl: 'https://maps.app.goo.gl/S8nZtNubGcSVEU1L6',
};
const basecampDay1Location = {
  coordinates: { north: '44.87892323751082', east: '22.415681370581954' },
  mapsUrl: 'https://maps.app.goo.gl/qita5o9rvVPX5QbC8',
  titleKey: 'hco.basecamp.day1Title',
  descriptionKey: 'hco.basecamp.day1Description',
};
const basecampDay2Location: HCOLocation = {
  coordinates: basecampDay1Location.coordinates,
  mapsUrl: basecampDay1Location.mapsUrl,
  titleKey: 'hco.basecamp.day2Title',
  descriptionKey: 'hco.basecamp.day2Description',
};
const basecampDay3Location: HCOLocation = {
  coordinates: { north: '44.8881902', east: '22.4240747' },
  mapsUrl: 'https://goo.gl/maps/WANKLe6jnrTYy1ov6',
  titleKey: 'hco.basecamp.day3Title',
  descriptionKey: 'hco.basecamp.day3Description',
};
const accommodationCampPiticu: HCOLocation = {
  coordinates: { north: '44°53\'14.3"N', east: '22°24\'49.8"E' },
  mapsUrl: 'https://goo.gl/maps/YH1mkY8K11DCGygq6',
  titleKey: 'hco.accommodation.option1Title',
  descriptionKey: 'hco.accommodation.option1Description',
};
const accommodationGoldenSpirit: HCOLocation = {
  coordinates: { north: '44.8763021', east: '22.410933' },
  mapsUrl: 'https://goo.gl/maps/2k9qCq1NbpmhJsDW6',
  titleKey: 'hco.accommodation.option5Title',
  descriptionKey: 'hco.accommodation.option5Description',
  phone: '+40255560016',
};
const accommodationPensRouaMunte: HCOLocation = {
  coordinates: { north: '44.866085', east: '22.4047407' },
  mapsUrl: 'https://g.page/RouaDeMunte?share',
  titleKey: 'hco.accommodation.option3Title',
  descriptionKey: 'hco.accommodation.option3Description',
  phone: '+40723769089',
};
const accommodationPensSafrane: HCOLocation = {
  coordinates: { north: '44°51\'38.3"N', east: '22°24\'09.5"E' },
  mapsUrl: 'https://goo.gl/maps/WuSpRMqScbrYpZuh6',
  titleKey: 'hco.accommodation.option2Title',
  descriptionKey: 'hco.accommodation.option2Description',
  phone: '+40255561000',
};
const accommodationPensCharisma: HCOLocation = {
  coordinates: { north: '44.8593788', east: '22.394071' },
  mapsUrl: 'https://goo.gl/maps/Jw1RxrNP3edN31J7A',
  titleKey: 'hco.accommodation.option4Title',
  descriptionKey: 'hco.accommodation.option4Description',
  phone: '+40749998271',
};

interface Coordinates {
  north: String;
  east: String;
}

export interface HCOLocation {
  coordinates: Coordinates;
  mapsUrl: String;
  phone?: String;
  titleKey?: string;
  descriptionKey?: string;
}

class HCOLocations {
  constructor(
    readonly location: HCOLocation,
    readonly cragLocation: HCOLocation,
    readonly basecampDay1: HCOLocation,
    readonly basecampDay2: HCOLocation,
    readonly basecampDay3: HCOLocation,
    readonly accommodations: Array<HCOLocation>
  ) {}
}

export const hcoLocations: HCOLocations = new HCOLocations(
  baseLocation,
  cragLocation,
  basecampDay1Location,
  basecampDay2Location,
  basecampDay3Location,
  [
    accommodationCampPiticu,
    accommodationPensSafrane,
    accommodationPensRouaMunte,
    accommodationPensCharisma,
    accommodationGoldenSpirit,
  ]
);
