import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { useCallback } from 'react';
import i18next from 'i18next';

const sxActiveLang = {
  cursor: 'pointer',
  fontSize: '0.8em',
  color: '#FFC506', //appTheme.palette.primary
  fontWeight: 'bold',
};

const sxInactiveLang = {
  cursor: 'pointer',
  fontSize: '0.8em',
  color: 'white',
};

export function LanguageMenu() {
  const language = i18next.language;
  const onChangeLanguage = useCallback(
    (lng: string) => i18next.changeLanguage(lng),
    []
  );

  const sxRO = {
    minWidth: 30,
    px: 0.5,
    ...(language === 'ro' ? sxActiveLang : sxInactiveLang),
  };

  const sxEN = {
    minWidth: 30,
    px: 0.5,
    ...(language !== 'ro' ? sxActiveLang : sxInactiveLang),
  };

  return (
    <Box>
      <Button sx={sxRO} onClick={() => onChangeLanguage('ro')}>
        RO
      </Button>
      <Button sx={sxEN} onClick={() => onChangeLanguage('en')}>
        EN
      </Button>
    </Box>
  );
}
