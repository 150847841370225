import {
  addDoc,
  CollectionReference,
  deleteDoc,
  doc,
  getDocs,
  PartialWithFieldValue,
  setDoc,
} from 'firebase/firestore/lite';
import { hcoRegistrationsCollection } from '../../firebase/firestoreData';
import { fromFirebaseRegistration } from './model/transformations/HCORegistrationFirebaseExt';
import { HCORegistration } from './model/HCORegistration';
import { HCOEdition } from './model/HCOEdition';

export class HCORegistrationsRepository {
  private readonly collection: CollectionReference;

  constructor(registrationsCollection: CollectionReference) {
    this.collection = registrationsCollection;
  }

  async fetchRegistrations(): Promise<HCORegistration[]> {
    console.log('fetchRegistrations loading!!!');
    const usersSnapshot = await getDocs(this.collection);
    return usersSnapshot.docs.map((doc) =>
      fromFirebaseRegistration(doc.data(), doc.id)
    );
  }

  async addRegistration(registration: HCORegistration) {
    await addDoc(this.collection, registration).then((it) => {
      console.log('HCOUser written to firebase, with ID: ', it);
    });
  }

  async updateRegistrationConfirmation(
    registrationId: String,
    confirmed: boolean
  ) {
    return this.updateRegistration(registrationId, {
      confirmed: confirmed,
      // @ts-ignore
      confirmationDate: confirmed ? new Date().toISOString() : null,
    });
  }

  private async updateRegistration(
    registrationId: String,
    data: PartialWithFieldValue<HCORegistration>
  ) {
    let registrationRef = doc(this.collection, registrationId.toString());
    await setDoc(registrationRef, data, { merge: true }).then((it) => {
      console.log('HCOUser updated in firebase, with ID: ', it);
    });
  }

  async deleteRegistration(registration: HCORegistration) {
    console.log('deleteRegistration: ' + registration.email);
    await deleteDoc(doc(this.collection, registration.id!.toString())).then(
      (it) => {
        console.log('HCOUser deleted from firebase, with ID: ', it);
      }
    );
  }
}

export const hcoRegistrationsRepository = (edition: HCOEdition) =>
  new HCORegistrationsRepository(hcoRegistrationsCollection(edition.id));
