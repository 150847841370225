export enum HCOPriceLabel {
  EARLY_BIRD,
  REGULAR,
  ON_THE_SPOT,
}

export class HCOPriceData {
  // @ts-ignore
  currentPriceLabel: HCOPriceLabel;
  // @ts-ignore
  price: string;
  // @ts-ignore
  title: string;
  // @ts-ignore
  hint: string;
  // @ts-ignore
  currencyRON: string;
  // @ts-ignore
  currencyEUR: string;

  constructor(currentPriceLabel: HCOPriceLabel) {
    this.setPriceFields(currentPriceLabel);
  }

  private setPriceFields(currentPriceLabel: HCOPriceLabel) {
    switch (currentPriceLabel) {
      case HCOPriceLabel.EARLY_BIRD:
        this.price = 'hco.registration.earlyBirdPrice';
        this.title = 'hco.registration.earlyBird';
        this.hint = 'hco.registration.earlyBirdHint';
        break;
      case HCOPriceLabel.REGULAR:
        this.price = 'hco.registration.regularPrice';
        this.title = 'hco.registration.regular';
        this.hint = 'hco.registration.regularHint';
        break;
      case HCOPriceLabel.ON_THE_SPOT:
        this.price = 'hco.registration.lateBloomerPrice';
        this.title = 'hco.registration.lateBloomer';
        this.hint = 'hco.registration.lateBloomerHint';
        break;
    }
    this.currentPriceLabel = currentPriceLabel;
    this.currencyRON = 'hco.registration.currencyRon';
    this.currencyEUR = 'hco.registration.currencyEur';
  }

  getColor(priceLabel: HCOPriceLabel): string {
    let color = '';
    if (this.currentPriceLabel === priceLabel) {
      color = 'white';
    }
    return color;
  }

  isCurrent(priceLabel: HCOPriceLabel): boolean {
    return this.currentPriceLabel === priceLabel;
  }
}

export const hcoCurrentPrice = new HCOPriceData(HCOPriceLabel.ON_THE_SPOT);
