import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  Dialog,
  Divider,
  IconButton,
  Slide,
  Stack,
  ThemeProvider,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import appTheme from '../../../theme/appTheme';
import Grid from '@mui/material/Grid';
import { HCOFormProps } from './form/HCOFormProps';
import { useTranslation } from 'react-i18next';
import {
  hcoCurrentPrice,
  HCOPriceLabel,
} from '../../../data/hco/model/HCOCurrentPrice';
import HCOForm from './form/HCOForm';
import { boxTheme } from '../../../theme/boxTheme';
import { currentEditionLocal } from '../../../data/hco/model/HCOEdition';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function HCORegisterCard({
  edition,
  registrationsCount,
  updateRegistrationsCount,
}: HCOFormProps) {
  const [formVisible, setFormVisible] = useState(false);
  const { t } = useTranslation();

  function toggleFormVisibility() {
    setFormVisible(!formVisible);
  }

  const registerButtonDisabled = hcoCurrentPrice.isCurrent(
    HCOPriceLabel.ON_THE_SPOT
  );

  return (
    <Box>
      <ThemeProvider theme={boxTheme}>
        <Card
          sx={{
            backgroundColor: appTheme.palette.primary.main,
            width: '100%',
            borderRadius: '15px',
          }}
        >
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={9} xs={12}>
              <Box sx={{ padding: 4 }}>
                <Typography
                  variant="h2"
                  sx={{ fontWeight: 'bold', fontFamily: 'inter-light' }}
                >
                  {t('hco.registration.payOnline.info1')}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    whiteSpace: 'pre-wrap',
                    fontWeight: 'bold',
                    fontFamily: 'inter-light',
                  }}
                >
                  {t('hco.registration.payOnline.info2')}
                  <br />
                  {t('hco.registration.payOnline.info3').replace(
                    '%s',
                    currentEditionLocal.year
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={3} xs={12} alignItems={'flex-end'}>
              <CardActions>
                <Button
                  variant="contained"
                  sx={{
                    width: '100%',
                    minHeight: '50px',
                    marginRight: 4,
                    marginLeft: 4,
                  }}
                  onClick={toggleFormVisibility}
                  disabled={registerButtonDisabled}
                >
                  {t('hco.registration.title')}
                </Button>
              </CardActions>
            </Grid>

            <Grid item md={12} xs={12}>
              <Divider color={'black'} sx={{ marginLeft: 4, marginRight: 4 }} />
            </Grid>

            <Grid item md={4} xs={12} sx={{ paddingTop: '0!important' as any }}>
              <Box
                sx={{
                  backgroundColor: hcoCurrentPrice.getColor(
                    HCOPriceLabel.EARLY_BIRD
                  ),
                  marginTop: 0,
                  padding: 2,
                  marginLeft: 4,
                  marginRight: 4,
                  marginBottom: 4,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 'bold', fontFamily: 'inter-light' }}
                >
                  {t('hco.registration.earlyBird')} -{' '}
                  {t('hco.registration.earlyBirdPrice')}
                  {t('hco.registration.currencyRon')}
                </Typography>
                <Typography
                  variant={'body1'}
                  sx={{ paddingTop: 2, fontFamily: 'inter-light' }}
                >
                  {t('hco.registration.earlyBirdHint')}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={4} xs={12} sx={{ paddingTop: '0!important' as any }}>
              <Box
                sx={{
                  backgroundColor: hcoCurrentPrice.getColor(
                    HCOPriceLabel.REGULAR
                  ),
                  marginTop: 0,
                  padding: 2,
                  marginLeft: 4,
                  marginRight: 2,
                  marginBottom: 4,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 'bold', fontFamily: 'inter-light' }}
                >
                  {t('hco.registration.regular')} -{' '}
                  {t('hco.registration.regularPrice')}
                  {t('hco.registration.currencyRon')}
                </Typography>
                <Typography
                  variant={'body1'}
                  sx={{ fontFamily: 'inter-light', paddingTop: 2 }}
                >
                  {t('hco.registration.regularHint')}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={4} xs={12} sx={{ paddingTop: '0!important' as any }}>
              <Box
                sx={{
                  backgroundColor: hcoCurrentPrice.getColor(
                    HCOPriceLabel.ON_THE_SPOT
                  ),
                  marginTop: 0,
                  padding: 2,
                  marginLeft: 4,
                  marginRight: 2,
                  marginBottom: 4,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 'bold', fontFamily: 'inter-light' }}
                >
                  {t('hco.registration.lateBloomer')} -{' '}
                  {t('hco.registration.lateBloomerPrice')}
                  {t('hco.registration.currencyRon')}
                </Typography>
                <Typography
                  variant={'body1'}
                  sx={{ fontFamily: 'inter-light', paddingTop: 2 }}
                >
                  {t('hco.registration.lateBloomerHint')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </ThemeProvider>

      <Dialog
        fullScreen
        open={formVisible}
        onClose={toggleFormVisibility}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            background: 'black',
          },
        }}
      >
        <Stack direction="row" justifyContent="end">
          <IconButton
            edge="start"
            onClick={toggleFormVisibility}
            aria-label="close"
            sx={{ p: 4 }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>

        <HCOForm
          edition={edition}
          registrationsCount={registrationsCount}
          updateRegistrationsCount={updateRegistrationsCount}
        />
      </Dialog>
    </Box>
  );
}
