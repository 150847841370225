import { DocumentData } from '@firebase/firestore/lite';
import { HCORegistration } from '../HCORegistration';

export function fromFirebaseRegistration(
  docData: DocumentData,
  id: String
): HCORegistration {
  return {
    id: id,
    address: docData.address,
    birthDate: docData.birthDate,
    category: docData.category,
    city: docData.city,
    club: docData.club,
    country: docData.country,
    email: docData.email,
    firstName: docData.firstName,
    lastName: docData.lastName,
    phone: docData.phone,
    sex: docData.sex,
    tshirtSize: docData.tshirtSize,
    termsAcceptedDate: docData.termsAcceptedDate,
    confirmed: docData.confirmed,
    confirmationDate: docData.confirmationDate,
    paymentType: docData.paymentType,
  };
}
