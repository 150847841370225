import { HCOEdition } from '../../../data/hco/model/HCOEdition';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import HCOHomeParagraphTitle from './HCOHomeParagraphTitle';
import appTheme from '../../../theme/appTheme';
import HCOHomeParagraphDescription, {
  descriptionSpacingBottom,
  TextSize,
} from './HCOHomeParagraphDescription';
import { hcoLocations } from '../../../data/hco/model/HCOLocations';
import Typography from '@mui/material/Typography';
import React from 'react';

const locationImage = (path: String) => {
  const locationImageStyle = {
    maxWidth: '100%',
    height: 'auto',
    padding: 0,
    margin: 0,
    borderRadius: '10px',
  };
  return (
    <img src={path.toString()} style={locationImageStyle} alt="location" />
  );
};

interface HCOHomeLocationProps {
  currentEdition: HCOEdition | undefined;
}

export function HCOHomeLocation({ currentEdition }: HCOHomeLocationProps) {
  const { t } = useTranslation();
  const editionNumber = currentEdition?.romanNumeral ?? '';
  let hcoLocation = hcoLocations.location;
  let hcoCragLocation = hcoLocations.cragLocation;

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <HCOHomeParagraphTitle
          text={t('hco.location.title') + ' - ' + editionNumber}
        />
      </Grid>

      <Grid item xs={12} md={12} id="crag">
        <HCOHomeParagraphTitle
          text={t('hco.location.crag')}
          textColor={appTheme.palette.text.secondary}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <HCOHomeParagraphDescription
          text={t('hco.location.cragDescription').replace(
            '%s',
            currentEdition?.romanNumeral ? currentEdition.romanNumeral : ''
          )}
        />
        <HCOHomeParagraphDescription
          text={t('hco.location.cragDescription2')}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <a
              href={hcoLocation.mapsUrl.toString()}
              target="_blank"
              rel="noreferrer"
            >
              {locationImage('/images/location_1_amfi.png')}
            </a>
          </Grid>
          <Grid item xs={12} md={4}>
            {locationImage('/images/location_2_amfi.jpeg')}
          </Grid>
          <Grid item xs={12} md={4}>
            {locationImage('/images/location_3_amfi.jpeg')}
          </Grid>
          <Grid item xs={12} md={12}>
            {descriptionSpacingBottom}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12}>
        <Grid container columnSpacing={20}>
          <Grid item xs={12} md={12}>
            <HCOHomeParagraphDescription
              text={t('hco.location.accessDescription1')}
              textSize={TextSize.MEDIUM}
            />
          </Grid>
          {/*<Grid item xs={12} md={6}>
            <HCOHomeParagraphDescription
              text={t('hco.location.accessDescription2')}
              textSize={TextSize.MEDIUM}
            />
          </Grid>*/}

          {/*<Grid item xs={12} md={6}>
            <HCOHomeParagraphDescription
              text={t('hco.location.accessDescription3')}
              textSize={TextSize.MEDIUM}
              textColor={'white'}
            />
          </Grid>*/}
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              <Grid item xs={6} md={4}>
                <Typography sx={{ fontWeight: 'bold', color: 'white' }}>
                  {t('hco.location.localizationTitle')}
                </Typography>
                <a
                  href={hcoCragLocation.mapsUrl.toString()}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textDecorationColor: appTheme.palette.primary.main,
                  }}
                >
                  <HCOHomeParagraphDescription
                    text={hcoCragLocation.coordinates.north}
                    textSize={TextSize.SMALL}
                    textColor={appTheme.palette.primary.main}
                    noSpacing={true}
                  />
                  <HCOHomeParagraphDescription
                    text={hcoCragLocation.coordinates.east}
                    textSize={TextSize.SMALL}
                    textColor={appTheme.palette.primary.main}
                    noSpacing={true}
                  />
                </a>
              </Grid>
              <Grid item xs={6} md={4} sx={{ p: { xs: 5, md: 10 } }}>
                <Typography sx={{ fontWeight: 'bold', color: 'white' }}>
                  {t('hco.location.accessTitle')}
                </Typography>
                <HCOHomeParagraphDescription
                  text={t('hco.location.accessText')}
                  textSize={TextSize.SMALL}
                  noSpacing={true}
                />
              </Grid>
              <Grid item xs={6} md={4} sx={{ p: { xs: 5, md: 10 } }}>
                <Typography sx={{ fontWeight: 'bold', color: 'white' }}>
                  {t('hco.location.orientationTitle')}
                </Typography>
                <HCOHomeParagraphDescription
                  text={t('hco.location.orientationText')}
                  textSize={TextSize.SMALL}
                  noSpacing={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
