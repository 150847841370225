import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Radio,
  Stack,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  HCORegistration,
  PaymentType,
} from '../../../../data/hco/model/HCORegistration';
import HCOFormFields from './HCOFormFields';
import HCORegistrationsNumber from './HCORegistrationsNumber';
import HCOLink from './HCOLink';
import { HCORegistrationValidator } from './HCORegistrationValidator';
import { HCOPrice } from '../../HCOPrice';
import { HCOFormProps } from './HCOFormProps';
import { useTranslation } from 'react-i18next';
import HCOPaymentInfo from './payment/HCOPaymentInfo';
import { hcoRegistrationsRepository } from '../../../../data/hco/HCORegistrationsRepository';

const initialUserState: HCORegistration = {
  address: '',
  birthDate: '',
  category: '',
  city: '',
  club: '',
  country: '',
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  sex: '',
  tshirtSize: '',
  paymentType: PaymentType.TRANSFER,
  confirmed: false,
};

type UserSavedState = {
  success?: boolean;
  error?: String;
  loading: boolean;
};

function HCOForm({
  edition,
  registrationsCount,
  updateRegistrationsCount,
}: HCOFormProps) {
  const [user, setUser] = useState<HCORegistration>(initialUserState);
  const [errorState, setErrorState] =
    useState<HCORegistration>(initialUserState);
  const [userSavedState, setUserSavedState] = useState<UserSavedState>();

  const { t } = useTranslation();

  function handleInputChange(userKey: String, value: String) {
    let userCopy = { ...user };
    let errorStateCopy = { ...errorState };

    type ObjectKey = keyof typeof userCopy;
    const key = userKey as ObjectKey;

    // termsAcceptedDate is updated by handleTermsCheckedChange
    if (key === 'termsAcceptedDate') return;
    if (key === 'confirmed') return;
    if (key === 'paymentType') return;

    userCopy[key] = value;
    setUser(userCopy);
    errorStateCopy[key] = '';
    setErrorState(errorStateCopy);

    console.log('change text field: ' + value + ', id: ' + userKey);
    console.log('changed user key: ' + JSON.stringify(userCopy));
  }

  function handleTermsCheckedChange(value: boolean) {
    let userCopy = { ...user };
    userCopy.termsAcceptedDate = value ? new Date().toISOString() : undefined;
    setUser(userCopy);
  }

  function handlePayChange(payOnline: boolean) {
    let userCopy = { ...user };
    userCopy.paymentType = payOnline
      ? PaymentType.ONLINE
      : PaymentType.TRANSFER;
    setUser(userCopy);
  }

  const paymentDisabled = () => {
    return user.termsAcceptedDate === undefined;
  };

  function toggleTermsAccepted() {
    handleTermsCheckedChange(!user.termsAcceptedDate);
  }

  function saveAndPay() {
    if (validate()) {
      console.log('Save user: ' + JSON.stringify(user));
      setUserSavedState({ loading: false });
      hcoRegistrationsRepository(edition)
        .addRegistration(user)
        .then(() => {
          setUserSavedState({ success: true, loading: false });
          updateRegistrationsCount();
          console.log('new user saved to firebase hco!');
        })
        .catch((ex) => {
          setUserSavedState({
            success: false,
            error: 'User saving error: ' + ex.error,
            loading: false,
          });
          console.log('Document saving failure: ', ex);
        });
    } else {
      console.log('User not saved, validate false ');
    }
  }

  function validate() {
    console.log('environment: ', process.env.NODE_ENV);

    let validator = new HCORegistrationValidator(errorState);
    const valid = validator.validate(user);
    setErrorState(validator.errorState);
    return valid;
  }

  const HCOForm = () => {
    return (
      <Box>
        <Typography fontFamily="inter-light" variant="h3" sx={{ pl: 0 }}>
          {t('hco.registration.header')}
        </Typography>
        <br />
        <br />
        {userSavedState?.error ? (
          <Typography sx={{ p: 1 }}>{userSavedState.error}</Typography>
        ) : (
          ''
        )}
        <HCOFormFields
          user={user}
          errorState={errorState}
          InputHandler={handleInputChange}
        />
        <Stack direction="row" alignItems="center" sx={{ pt: 1, ml: -1.5 }}>
          <Checkbox
            checked={user.termsAcceptedDate !== undefined}
            onChange={(event) => handleTermsCheckedChange(event.target.checked)}
          />
          <Typography onClick={toggleTermsAccepted} sx={{ cursor: 'pointer' }}>
            {t('hco.registration.termsAccept')}&nbsp;
            <HCOLink
              url="/hco#competition"
              label={t('hco.registration.termsAcceptRules')}
            />
          </Typography>
        </Stack>
      </Box>
    );
  };

  const HCOFormActions = () => {
    const payOnline = user.paymentType === PaymentType.ONLINE;
    const buttonsMinWidth = { xs: '100%', md: '400px' };
    return (
      <Box display="flex" flexDirection="column" justifyContent="center">
        <HCOPrice />
        <Button
          variant="contained"
          color={payOnline ? 'secondary' : 'info'}
          sx={{
            minWidth: buttonsMinWidth,
            mt: 5,
            mb: 1,
          }}
          onClick={() => handlePayChange(true)}
        >
          <Box
            display="flex"
            justifyContent="start"
            alignItems="flex-start"
            sx={{ width: '100%' }}
          >
            <Radio
              checked={payOnline}
              value={true}
              sx={{ padding: 0 }}
              onChange={() => handlePayChange(true)}
            />
            <Typography variant="body2" sx={{ padding: 0.5 }}>
              {t('hco.registration.payModeOnline')}
            </Typography>
          </Box>
        </Button>

        <Button
          variant="contained"
          color={payOnline ? 'info' : 'secondary'}
          sx={{
            minWidth: '350px',
            mb: 3,
          }}
          onClick={() => handlePayChange(false)}
        >
          <Box
            display="flex"
            justifyContent="start"
            sx={{ width: '100%', alignItems: 'true' }}
          >
            <Radio
              checked={!payOnline}
              value={false}
              sx={{ padding: 0 }}
              onChange={() => handlePayChange(false)}
            />
            <Typography variant="body2" sx={{ padding: 0.5 }}>
              {t('hco.registration.payModeTransfer')}
            </Typography>
          </Box>
        </Button>

        <Button
          variant="contained"
          disabled={paymentDisabled()}
          sx={{
            minWidth: '350px',
            padding: 1.5,
            mb: 5,
            fontWeight: 'bold',
          }}
          onClick={saveAndPay}
        >
          {t('hco.registration.pay')}
        </Button>

        {registrationsCount === undefined ? (
          <CircularProgress />
        ) : (
          <HCORegistrationsNumber userCount={registrationsCount} />
        )}
      </Box>
    );
  };

  function loading() {
    return userSavedState?.loading ? (
      <Box
        sx={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          zIndex: 2,
          mt: -10,
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    ) : (
      ''
    );
  }

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      {loading()}
      <Grid
        container
        sx={{ pl: { xs: 3, md: 10 }, pr: { xs: 3, md: 10 }, pt: 2 }}
        direction="row"
        justifyContent="center"
        alignItems="top"
      >
        {userSavedState?.success ? (
          <Grid item md={12}>
            <HCOPaymentInfo paymentType={user.paymentType!} />
          </Grid>
        ) : (
          <Grid item md={6}>
            {HCOForm()}
          </Grid>
        )}

        <Grid item md={6}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              md={12}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                pt: { xs: 5, md: 13 },
                pl: { xs: 3, md: 5 },
                pr: { xs: 3, md: 5 },
              }}
            >
              {userSavedState?.success ? '' : HCOFormActions()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default HCOForm;
