import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import Footer from '../../Footer';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import HCOHomeParagraphDescription, {
  TextSize,
} from './HCOHomeParagraphDescription';
import { HCOHomeParagraphDivider } from './HCOHomeParagraphDivider';
import { currentEditionLocal } from '../../../data/hco/model/HCOEdition';
import { BackArrowHeader } from '../../BackArrowHeader';
import { HCOHomeRules } from './HCOHomeRules';
import { HCOHomeLocation } from './HCOHomeLocation';
import { MenuLink } from '../../common/MenuLink';
import { HCOHomeRegistration } from './HCOHomeRegistration';
import { HCOHomeFestival } from './HCOHomeFestival';
import { HCOHomeAccommodation } from './HCOHomeAccommodation';
import { HCOHomeBasecamp } from './HCOHomeBasecamp';
import { LanguageMenu } from '../../menudrawer/LanguageMenu';

function HCOHome() {
  /*const [currentEdition, setCurrentEditionState] = useState<
    HCOEdition | undefined
  >();*/
  const { t } = useTranslation();
  const { pathname, hash } = useLocation();
  const currentEdition = currentEditionLocal;

  useEffect(() => {
    const elem = hash ? document.getElementById(hash.substring(1)) : null;
    console.log('Hash is', hash, 'element is', elem);
    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth' });
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);

  return (
    <Container maxWidth="xl">
      <Box padding={0} overflow={'hidden'}>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          sx={{ marginTop: 3 }}
        >
          <BackArrowHeader />
          <LanguageMenu />
        </Box>

        <Grid container paddingBottom={0} spacing={2}>
          <Grid item xs={12} md={12} sx={{ marginTop: 10, marginBottom: 10 }}>
            <Typography
              sx={{
                fontSize: { md: '8.5rem', xs: '4rem' },
                lineHeight: '1.2',
                fontWeight: '300',
                color: 'white',
                fontFamily: 'inter-light',
                maxWidth: '100%',
              }}
            >
              {t('hco.title') + ' ' + new Date().getFullYear()}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container paddingBottom={0} spacing={10}>
              <Grid item xs={12} md={6}>
                <HCOHomeParagraphDescription
                  text={t('hco.description').replace('%s', currentEdition.year)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  paddingBottom={0}
                  spacing={3}
                  justifyContent={{ md: 'center' }}
                  alignContent={{ md: 'center' }}
                  alignItems={{ md: 'center' }}
                >
                  <Grid item xs={6} md={3}>
                    <Typography sx={{ fontWeight: 'bold', color: 'white' }}>
                      {t('hco.when')}
                    </Typography>
                    <HCOHomeParagraphDescription
                      text={t('hco.whenDate').replace(
                        '%s',
                        currentEdition.days
                      )}
                      textSize={TextSize.SMALL}
                      noSpacing={true}
                    />
                    <HCOHomeParagraphDescription
                      text={t('hco.edition')
                        .replace(
                          '$1',
                          currentEdition?.year ? currentEdition.year : ''
                        )
                        .replace(
                          '$2',
                          currentEdition?.romanNumeral
                            ? currentEdition.romanNumeral
                            : ''
                        )}
                      textSize={TextSize.SMALL}
                      noSpacing={true}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography sx={{ fontWeight: 'bold', color: 'white' }}>
                      {t('hco.where')}
                    </Typography>
                    <HCOHomeParagraphDescription
                      text={currentEdition.location}
                      textSize={TextSize.SMALL}
                      noSpacing={true}
                    />
                    <HCOHomeParagraphDescription
                      text={t('hco.whereCity')}
                      textSize={TextSize.SMALL}
                      noSpacing={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <HCOHomeParagraphDivider />

        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            {MenuLink('festival', t('hco.festival.title'))}
          </Grid>
          <Grid item xs={12} md={2}>
            {MenuLink('location', t('hco.location.title'))}
          </Grid>
          <Grid item xs={12} md={2}>
            {MenuLink('competition', t('hco.competition.rules'))}
          </Grid>
          <Grid item xs={12} md={2}>
            {MenuLink('register', t('hco.registration.title'), true)}
          </Grid>
          <Grid item xs={12} md={2}>
            {MenuLink('participants', t('hco.participants.title'))}
          </Grid>
        </Grid>

        <HCOHomeParagraphDivider />

        <Box id="festival">
          <HCOHomeFestival />
        </Box>

        <HCOHomeParagraphDivider />

        <Box id="location">
          <HCOHomeLocation currentEdition={currentEdition} />
        </Box>

        <Box id="basecamp">
          <HCOHomeBasecamp />
        </Box>

        {/*<HCOHomeAccommodation />*/}

        <HCOHomeParagraphDivider />

        <Box id="competition">
          <HCOHomeRules />
        </Box>

        <Box id="register">
          <HCOHomeRegistration currentEdition={currentEdition} />
        </Box>

        <Footer />
      </Box>
    </Container>
  );
}

export default HCOHome;
