export interface HCOEdition {
  id: string;
  year: string;
  number: number;
  romanNumeral: string;
  location: string;
}

class HCOEditionImpl implements HCOEdition {
  id: string;
  year: string;
  number: number;
  romanNumeral: string;
  location: string;
  days: string;
  constructor(
    id: string,
    year: string,
    number: number,
    romanNumeral: string,
    location: string,
    days: string
  ) {
    this.id = id;
    this.year = year;
    this.number = number;
    this.romanNumeral = romanNumeral;
    this.location = location;
    this.days = days;
  }
}

export const currentEditionLocal = new HCOEditionImpl(
  '2024',
  '2024',
  22,
  'XXII',
  'Peretele Șoimului, Amfiteatru',
  '5-7'
);
