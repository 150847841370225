import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { HCORegistration } from '../../data/hco/model/HCORegistration';
import { useTranslation } from 'react-i18next';

interface HCOUserDeleteProps {
  user: HCORegistration;
  handleDeleteUser: (user: HCORegistration) => any;
}

export default function HCORegistrationDeleteButtonDialog({
  user,
  handleDeleteUser,
}: HCOUserDeleteProps) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        sx={{ color: 'red' }}
        onClick={(event) => {
          event.stopPropagation();
          handleClickOpen();
        }}
      >
        Delete
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('hco.admin.deleteUserAlertTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('hco.admin.deleteUserAlertMessage').replace(
              '%s',
              user.lastName + ' ' + user.firstName
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleClose();
            }}
          >
            {t('hco.admin.disagree')}
          </Button>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleDeleteUser(user);
            }}
            autoFocus
          >
            {t('hco.admin.agree')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
