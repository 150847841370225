import React from 'react';
import {
  categories,
  HCORegistration,
  sex,
  tshirtSizes,
} from '../../../../data/hco/model/HCORegistration';
import Grid from '@mui/material/Grid';
import { InputLabel, MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface HCOUserProps {
  user: HCORegistration;
  errorState: HCORegistration;
  InputHandler: (userKey: String, value: String) => void;
}

function HCOFormFields({ user, errorState, InputHandler }: HCOUserProps) {
  const { t } = useTranslation();
  const textFieldVariant = 'filled';
  const gridItemTextFieldSx = {
    '& .MuiTextField-root': { width: '100%' },
  };
  const textFieldInputProps = { disableUnderline: true };
  const textFieldSx = {
    backgroundColor: '#424242',
    borderRadius: 1,
    '& input, & .MuiSelect-select': {
      padding: '13px',
    },
  };

  const makeInputChangeHandler = (fieldName: string) => (event: any) =>
    InputHandler(fieldName, event.target.value);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} sx={gridItemTextFieldSx}>
        <InputLabel htmlFor="lastName" required>
          {t('hco.registration.lastName')}
        </InputLabel>
        <TextField
          required
          error={errorState?.lastName !== ''}
          helperText={errorState?.lastName}
          id="lastName"
          variant={textFieldVariant}
          onChange={makeInputChangeHandler('lastName')}
          size="small"
          sx={textFieldSx}
          InputProps={textFieldInputProps}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={gridItemTextFieldSx}>
        <InputLabel htmlFor="firstName" required>
          {t('hco.registration.firstName')}
        </InputLabel>
        <TextField
          required
          error={errorState?.firstName !== ''}
          helperText={errorState?.firstName}
          id="firstName"
          variant={textFieldVariant}
          value={user?.firstName}
          onChange={makeInputChangeHandler('firstName')}
          size="small"
          sx={textFieldSx}
          InputProps={textFieldInputProps}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={gridItemTextFieldSx}>
        <InputLabel htmlFor="email" required>
          {t('hco.registration.email')}
        </InputLabel>
        <TextField
          required
          error={errorState?.email !== ''}
          helperText={errorState?.email}
          id="email"
          type="email"
          autoComplete="current-email"
          variant={textFieldVariant}
          onChange={makeInputChangeHandler('email')}
          size="small"
          sx={textFieldSx}
          InputProps={textFieldInputProps}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={gridItemTextFieldSx}>
        <InputLabel htmlFor="phone">{t('hco.registration.phone')}</InputLabel>
        <TextField
          error={errorState?.phone !== ''}
          helperText={errorState?.phone}
          id="phone"
          variant={textFieldVariant}
          onChange={makeInputChangeHandler('phone')}
          size="small"
          sx={textFieldSx}
          InputProps={textFieldInputProps}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={gridItemTextFieldSx}>
        <InputLabel htmlFor="country" required>
          {t('hco.registration.country')}
        </InputLabel>
        <TextField
          required
          error={errorState?.country !== ''}
          helperText={errorState?.country}
          id="country"
          type="text"
          variant={textFieldVariant}
          onChange={makeInputChangeHandler('country')}
          size="small"
          sx={textFieldSx}
          InputProps={textFieldInputProps}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={gridItemTextFieldSx}>
        <InputLabel htmlFor="city" required>
          {t('hco.registration.city')}
        </InputLabel>
        <TextField
          required
          error={errorState?.city !== ''}
          helperText={errorState?.city}
          id="city"
          variant={textFieldVariant}
          onChange={makeInputChangeHandler('city')}
          size="small"
          sx={textFieldSx}
          InputProps={textFieldInputProps}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={gridItemTextFieldSx}>
        <InputLabel htmlFor="address">
          {t('hco.registration.address')}
        </InputLabel>
        <TextField
          error={errorState?.address !== ''}
          helperText={errorState?.address}
          id="address"
          variant={textFieldVariant}
          onChange={makeInputChangeHandler('address')}
          size="small"
          sx={textFieldSx}
          InputProps={textFieldInputProps}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={gridItemTextFieldSx}>
        <InputLabel htmlFor="club">{t('hco.registration.club')}</InputLabel>
        <TextField
          id="club"
          variant={textFieldVariant}
          onChange={makeInputChangeHandler('club')}
          size="small"
          sx={textFieldSx}
          InputProps={textFieldInputProps}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={gridItemTextFieldSx}>
        <InputLabel htmlFor="birthDate" required>
          {t('hco.registration.birthDate')}
        </InputLabel>
        <TextField
          required
          error={errorState?.birthDate !== ''}
          helperText={errorState?.birthDate}
          id="birthDate"
          type="date"
          variant={textFieldVariant}
          onChange={makeInputChangeHandler('birthDate')}
          size="small"
          sx={textFieldSx}
          InputProps={textFieldInputProps}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={gridItemTextFieldSx}>
        <InputLabel htmlFor="sex" required>
          {t('hco.registration.sex')}
        </InputLabel>
        <TextField
          required
          error={errorState?.sex !== ''}
          helperText={errorState?.sex}
          id="sex"
          select
          value={user?.sex}
          variant={textFieldVariant}
          onChange={makeInputChangeHandler('sex')}
          size="small"
          sx={textFieldSx}
          InputProps={textFieldInputProps}
        >
          {sex.map((sexEntry) => (
            <MenuItem key={sexEntry.value} value={sexEntry.value}>
              {sexEntry.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md={6} sx={gridItemTextFieldSx}>
        <InputLabel htmlFor="category" required>
          {t('hco.registration.category')}
        </InputLabel>
        <TextField
          required
          error={errorState?.category !== ''}
          helperText={errorState?.category}
          id="category"
          select
          value={user?.category}
          variant={textFieldVariant}
          onChange={makeInputChangeHandler('category')}
          size="small"
          sx={textFieldSx}
          InputProps={textFieldInputProps}
        >
          {categories.map((cat) => (
            <MenuItem key={cat.value} value={cat.value}>
              {cat.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md={6} sx={gridItemTextFieldSx}>
        <InputLabel htmlFor="tshirtSize" required>
          {t('hco.registration.tshirtSize')}
        </InputLabel>
        <TextField
          required
          error={errorState?.tshirtSize !== ''}
          helperText={errorState?.tshirtSize}
          id="tshirtSize"
          select
          value={user.tshirtSize}
          variant={textFieldVariant}
          onChange={makeInputChangeHandler('tshirtSize')}
          size="small"
          sx={textFieldSx}
          InputProps={textFieldInputProps}
        >
          {tshirtSizes.map((ts) => (
            <MenuItem id="tshirtSize" key={ts.value} value={ts.value}>
              {ts.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
}

export default HCOFormFields;
