import i18next, { t } from 'i18next';

export interface HCORegistration {
  id?: String;
  firstName: String;
  lastName: String;
  email: String;
  phone: String;
  country: String;
  city: String;
  address: String;
  club: String;
  birthDate: String;
  sex: String;
  category: String;
  tshirtSize: String;
  termsAcceptedDate?: String;
  confirmed?: boolean;
  confirmationDate?: String;
  paymentType?: PaymentType;
}

export function formatRegisteredDate(user?: HCORegistration) {
  if (user === undefined || user.termsAcceptedDate === undefined) {
    return '-';
  }
  let date = new Date(Date.parse(user.termsAcceptedDate.toString()));
  return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
}

export function categoryText(user?: HCORegistration) {
  if (user === undefined || user.termsAcceptedDate === undefined) {
    return '';
  }
  let categoryTxt = categories.find(
    (cat) => user?.category === cat.value
  )?.label;
  let sexTxt = sex.find((sex) => user?.sex === sex.value)?.label;
  return (categoryTxt ? categoryTxt : '') + ' ' + sexTxt;
}

export const tshirtSizes = [
  {
    value: 'xs',
    label: 'XS',
  },
  {
    value: 's',
    label: 'S',
  },
  {
    value: 'm',
    label: 'M',
  },
  {
    value: 'l',
    label: 'L',
  },
  {
    value: 'xl',
    label: 'XL',
  },
];

export const categories = [
  {
    value: 'open',
    label: 'Open',
  },
  {
    value: 'master',
    label: 'Masters',
  },
];

export const sex = [
  {
    value: 'm',
    label: 'Masculin',
  },
  {
    value: 'f',
    label: 'Feminin',
  },
];

export enum PaymentType {
  ONLINE = 'online',
  TRANSFER = 'transfer',
}

i18next.on('languageChanged', function (lng) {
  categories.forEach((item) => {
    item.label = t(`hco.participant.categoryType.${item.value}`);
  });
  sex.forEach((item) => {
    item.label = t(`hco.participant.sex.${item.value}`);
  });
});
