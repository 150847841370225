import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import HCOHomeParagraphTitle from './HCOHomeParagraphTitle';
import HCOHomeParagraphDescription from './HCOHomeParagraphDescription';
import React from 'react';
import { currentEditionLocal } from '../../../data/hco/model/HCOEdition';
import { getOrdinalSuffix } from '../../../util/ordinalHelper';

export function HCOHomeFestival() {
    const { t } = useTranslation();

    const editionNumber = currentEditionLocal.number;
    const ordinalEdition = getOrdinalSuffix(editionNumber);

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
                <HCOHomeParagraphTitle text={t('hco.festival.title')} />
            </Grid>
            <Grid item xs={12} md={12}>
                <HCOHomeParagraphDescription
                    text={t('hco.festival.description')
                        .replace('$1', editionNumber?.toString() || '')
                        .replace('$2', ordinalEdition)} //english resource only
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <HCOHomeParagraphTitle text={t('hco.festival.newcomerTitle')} />
            </Grid>

            <Grid item xs={12} md={12}>
                <HCOHomeParagraphDescription text={t('hco.festival.newcomerSubtitle')} />
                <HCOHomeParagraphDescription text={t('hco.festival.newcomerInfo')} />
            </Grid>
        </Grid>
    );
}
