import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import HCOHomeParagraphTitle from './HCOHomeParagraphTitle';
import appTheme from '../../../theme/appTheme';
import HCOHomeParagraphDescription, {
  descriptionSpacingBottom,
} from './HCOHomeParagraphDescription';
import { HCOHomeLocationInfo } from './HCOHomeLocationInfo';
import { hcoLocations } from '../../../data/hco/model/HCOLocations';
import React from 'react';

export function HCOHomeBasecamp() {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <HCOHomeParagraphTitle
          text={t('hco.basecamp.title')}
          textColor={appTheme.palette.text.secondary}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <HCOHomeParagraphDescription text={t('hco.basecamp.description1')} />
        <HCOHomeParagraphDescription text={t('hco.basecamp.description2')} />
        <HCOHomeParagraphDescription text={t('hco.basecamp.description3')} />
      </Grid>
      <Grid item xs={12} md={6}>
        {descriptionSpacingBottom}
        {HCOHomeLocationInfo(hcoLocations.basecampDay1)}
        {HCOHomeLocationInfo(hcoLocations.basecampDay2)}
        {HCOHomeLocationInfo(hcoLocations.basecampDay3)}
        {descriptionSpacingBottom}
      </Grid>

      <Grid item xs={12} md={6}>
        <img
          src={'/images/location_amfi.png'}
          alt="location"
          style={{
            maxWidth: '90%',
            height: 'auto',
            paddingTop: 0,
            margin: 0,
          }}
        />
      </Grid>
    </Grid>
  );
}
