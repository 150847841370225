import React from 'react';
import { Stack, Typography } from '@mui/material';
import appTheme from '../../theme/appTheme';
import { useTranslation } from 'react-i18next';
import { hcoCurrentPrice } from '../../data/hco/model/HCOCurrentPrice';

export function HCOPrice() {
  const priceVariant = { xs: 'h4', md: 'h3' };
  const currencyVariant = 'body2';
  const { t } = useTranslation();

  return (
    <Stack direction="row" justifyContent="center" alignItems="top">
      <Typography fontFamily="inter-light" sx={{ typography: priceVariant }}>
        {t(hcoCurrentPrice.title) + ' - ' + t(hcoCurrentPrice.price)}
      </Typography>
      <Typography
        variant={currencyVariant}
        color={appTheme.palette.text.secondary}
      >
        {t(hcoCurrentPrice.currencyRON)}
      </Typography>
    </Stack>
  );
}
