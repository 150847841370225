import React from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Force from '../../../../../resources/hco/force.png';
import appTheme from '../../../../../theme/appTheme';
import { PaymentType } from '../../../../../data/hco/model/HCORegistration';
import PaymentLink from './PaymentLink';
import BancInfo from './BancInfo';
import { hcoCurrentPrice } from '../../../../../data/hco/model/HCOCurrentPrice';

export interface HCOPaymentInfoProps {
  paymentType: PaymentType;
}

export default function HCOPaymentInfo({ paymentType }: HCOPaymentInfoProps) {
  const { t } = useTranslation();
  const hint =
    paymentType === PaymentType.ONLINE
      ? t('hco.registration.participantRegisteredPayHint')
      : t('hco.registration.participantRegisteredPayHintCard');

  const paymentInfo = () => {
    return paymentType === PaymentType.ONLINE ? (
      <PaymentLink />
    ) : (
      <Box>
        <BancInfo />
        {getDivider()}
      </Box>
    );
  };

  function getDivider() {
    return <Divider color={'#414141'} sx={{ mb: 2, mt: 2 }} />;
  }

  return (
    <Box
      flexDirection="column"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box sx={{ pb: 3 }}>
        <img
          src={Force}
          width="60px"
          title={t('hco.force')}
          alt={t('hco.force')}
        />
      </Box>
      <Typography variant="h3" align={'center'} sx={{ pb: 2 }}>
        {t('hco.registration.participantRegisteredSuccess')}
      </Typography>
      <Stack sx={{ maxWidth: { xs: 'unset', md: '40%' }, pb: 4 }}>
        <Typography
          variant="h6"
          color={appTheme.palette.text.secondary}
          align={'center'}
        >
          {hint}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="center" alignItems="top">
        <Typography
          fontFamily="inter-light"
          variant="h4"
          align={'center'}
          sx={{ pb: 2 }}
        >
          {t(hcoCurrentPrice.title) + ' - ' + t(hcoCurrentPrice.price)}
        </Typography>
        <Typography
          variant={'body2'}
          color={appTheme.palette.text.secondary}
          fontFamily={'inter-light'}
        >
          {t(hcoCurrentPrice.currencyRON)}
        </Typography>
      </Stack>

      <Stack>
        {getDivider()}
        {paymentInfo()}
      </Stack>
    </Box>
  );
}
