import React from 'react'
import {Link} from "@mui/material";

interface HCOLinkProps {
    url: string,
    label: string
}

export default function HCOLink({url, label}: HCOLinkProps) {
    return (
        <Link href={url}
              target="_blank"
              rel="noreferrer"
              underline="none">
            {label}
        </Link>
    )
}