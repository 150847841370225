import React, { forwardRef } from 'react';
import { LinkProps as RouterLinkProps } from 'react-router-dom';

// @ts-ignore
import { NavHashLink } from 'react-router-hash-link';

export const CustomLink = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const scrollWithOffset = (el: any) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -92;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const { href, ...other } = props;
  return (
    <NavHashLink
      smooth
      ref={ref}
      to={href}
      scroll={(el: any) => scrollWithOffset(el)}
      {...other}
    />
  );
});
