import i18next, { t } from 'i18next';
import { HCORegistration } from '../../../../data/hco/model/HCORegistration';

const errors: any = {
  requiredField: 'Obligatoriu',
  invalidEmail: 'Adresa de email nu este valida',
};

i18next.on('languageChanged', function (lng) {
  Object.keys(errors).forEach((key) => {
    errors[key] = t(`errors.${key}`);
  });
});

export class HCORegistrationValidator {
  errorState: HCORegistration;

  constructor(errorState: HCORegistration) {
    this.errorState = errorState;
  }

  validate(user: HCORegistration) {
    let valid = true;
    const errorStateNew = { ...this.errorState };

    if (user.firstName === '') {
      errorStateNew.firstName = errors.requiredField;
      valid = false;
    }
    if (user.lastName === '') {
      errorStateNew.lastName = errors.requiredField;
      valid = false;
    }
    if (user.email === '') {
      errorStateNew.email = errors.requiredField;
      valid = false;
    } else if (
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(user.email.toString())
    ) {
      errorStateNew.email = errors.invalidEmail;
      valid = false;
    }
    if (user.birthDate === '') {
      errorStateNew.birthDate = errors.requiredField;
      valid = false;
    }
    if (user.country === '') {
      errorStateNew.country = errors.requiredField;
      valid = false;
    }
    if (user.city === '') {
      errorStateNew.city = errors.requiredField;
      valid = false;
    }
    if (user.category === '') {
      errorStateNew.category = errors.requiredField;
      valid = false;
    }
    if (user.sex === '') {
      errorStateNew.sex = errors.requiredField;
      valid = false;
    }
    if (user.tshirtSize === '') {
      errorStateNew.tshirtSize = errors.requiredField;
      valid = false;
    }
    console.log(
      'error state validation: ' +
        JSON.stringify(this.errorState) +
        JSON.stringify(errorStateNew)
    );

    this.errorState = errorStateNew;
    console.log(' validation state: ', valid);
    return valid;
  }
}
