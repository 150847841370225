import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function PaymentLink() {
  const { t } = useTranslation();

  return (
    <Box sx={{ width: { md: '500px', xs: '400px' } }}>
      <a
        target="blank"
        href="https://epl.ro/q/NXhoa52WRVh5tK-6ckYCEyKzH3HhN*g="
        style={{ textDecoration: 'none', color: 'black' }}
        rel="noreferrer"
      >
        <Typography
          fontWeight={'bold'}
          align={'center'}
          sx={{
            color: 'appTheme.palette.primary',
            fontSize: 'larger',
            backgroundColor: '#FFC506',
            p: 1,
          }}
        >
          {t('hco.registration.linkToPayment')}
        </Typography>
      </a>
    </Box>
  );
}
