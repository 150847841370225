import React from 'react';
import Grid from '@mui/material/Grid';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import appTheme from '../theme/appTheme';

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: 'black',
  color: theme.palette.text.primary,
}));

const MediaKitLink = styled('a')({
  color: 'white',
  '&:visited': { color: 'white' },
});
export default function Footer() {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} sx={{ pt: { md: 20, xs: 10 } }}>
      <Grid item xs={12} md={12}>
        <Divider color={'#414141'} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Box
          sx={{
            display: 'flex',
            paddingTop: 1,
            paddingBottom: 1,
            backgroundColor: 'black',
            borderRadius: 1,
          }}
        >
          <Item sx={{ flexGrow: 1 }}>
            <Typography
              variant={'h4'}
              sx={{
                fontWeight: 'bold',
                color: appTheme.palette.text.secondary,
              }}
            >
              {t('CSA.name')}
            </Typography>
          </Item>
          <Item>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/alternativetm.ro"
            >
              <FacebookRoundedIcon
                sx={{
                  fontSize: 'xxx-large',
                  p: 1,
                  color: '#FFC506',
                }}
              />
            </a>
          </Item>
          <Item>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/csalternative"
            >
              <InstagramIcon
                sx={{
                  fontSize: 'xxx-large',
                  p: 1,
                  color: '#FFC506',
                }}
              />
            </a>
          </Item>
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Divider color={'#414141'} />
      </Grid>
      <Grid item xs={12} md={3}>
        <Typography fontWeight="bold" color="white">
          CIF: 15156297
        </Typography>
        <Typography color="white">RO65 RNCB 0255 1146 1034 0001</Typography>
        <Typography color="white">
          Timisoara, B-dul Eroilor Nr. 7C, Ap. 11
        </Typography>
        <Typography color="white">office@alternativetm.ro</Typography>
      </Grid>
      <Grid item xs={12} md={3} pr={4}>
        <Typography fontWeight="bold" color="white">
          {t('footer.mediaKit.title')}
        </Typography>
        <Typography color="white">
          <MediaKitLink
            href="/files/MediaKit.zip"
            target="_blank"
            rel="noreferrer"
          >
            {t('footer.mediaKit.link')}
          </MediaKitLink>
          &nbsp;
          {t('footer.mediaKit.description')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={2}>
        <Typography fontWeight="bold" color="white">
          Andrei Simu
        </Typography>
        <Typography color="white">{t('footer.president')}</Typography>
        <Typography color="white">0722 599 386</Typography>
        <Typography color="white">mardaleclimb@yahoo.com</Typography>
      </Grid>
      <Grid item xs={12} md={2}>
        <Typography fontWeight="bold" color="white">
          Gelu Dănilă
        </Typography>
        <Typography color="white">{t('footer.secretary')}</Typography>
        <Typography color="white">0723 207 298</Typography>
        <Typography color="white">gdanila@yahoo.com</Typography>
      </Grid>
      <Grid item xs={12} md={2}>
        <Typography fontWeight="bold" color="white">
          Ioana Farcaș
        </Typography>
        <Typography color="white">{t('footer.mc')}</Typography>
        <Typography color="white">0720 414 323</Typography>
        <Typography color="white" paddingBottom={20}>
          ioana.batran@gmail.com
        </Typography>
      </Grid>
    </Grid>
  );
}
