import React from 'react';
import Typography from '@mui/material/Typography';

interface HCOParagraphTitle {
  text: String;
  textColor?: String;
}

export default function HCOHomeParagraphTitle({
  text,
  textColor,
}: HCOParagraphTitle) {
  const color = textColor ? textColor.toString() : 'white';
  const sx = {
    color: color,
    pb: 2,
    fontFamily: 'inter-light',
    fontSize: { md: '2rem', xs: '1.5rem' },
  };

  return (
    <Typography variant={'h6'} sx={sx}>
      {text}
    </Typography>
  );
}
